@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

@font-face {
	font-family: 'tan-pearl';
	src: url('../../dist/font/TAN-PEARL-Regular.woff2') format('woff2'),
			url('../../dist/font/TAN-PEARL-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family:"bebas-neue-pro";
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

h1,h2,h3,h4,p,ul,li{
	margin: 0;
	padding: 0;
	list-style: none;
}

.section-heading{
	font-size: 160px;
	text-transform: uppercase;
	text-align: center;
	-webkit-text-stroke: 2px $pink;
	color: transparent;
	margin-bottom: -60px;
	@include tablet-up{
		font-size: 300px;
		margin-bottom: -160px;
	}
}

.slider,.videos-slider{
	transform: translateX(-10px);
	.slick-list{
		overflow: visible;
		margin: 0 -5px;
		.slick-slide {
			margin: 0 5px;
		}
	}
	
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

header {
	button{
		background-color: $pink;
		text-transform: uppercase;
		font-family: $serif;
		color: $black;
		font-size: 30px;
		display: block;
		padding: 15px;
		text-align: center;
		width: 100%;
		border: solid 1px $black;
		@include hover-focus{
			color: $white;
		}
		@include tablet-up{
			display: none;
		}
	}
	.menu{
		position: absolute;
		opacity: 0;
		pointer-events: none;
		transition: all .4s;
		width: 100%;
		z-index: 999;
		a{
			background-color: $pink;
			text-transform: uppercase;
			font-family: $serif;
			color: $black;
			font-size: 30px;
			display: block;
			padding: 15px;
			text-align: center;
			width: 100%;
			border: solid 1px $black;
			border-top: none;
			&.music-nav{
				video{
					object-position: center 22%;
				}
			}
			@include hover-focus{
				color: $white;
				video{
					opacity: 1;
				}
			}
			position: relative;
			video{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
				transition: all .4s;
				opacity: 0;
			}
			span{
				position: relative;
				z-index: 9;
			}
		}
		&.active{
			opacity: 1;
			pointer-events: all;
		}
		@include tablet-up{
			@include flex(row,initial,initial);
			opacity: 1;
			position: initial;
			pointer-events: all;
			li{
				flex: 1;
			}
		}
	}
}

.mobile{
	@include tablet-up{
		display: none;
	}
}

.desktop{
	display: none;
	@include tablet-up{
		display: block;
	}
}

.btn{
	display: inline-block;
	font-family: $serif;
	text-transform: uppercase;
	color: $black;
	border-radius: 50px;
	padding: 10px 20px;
	border: none;
	border: none;
	font-size: 16px;
}

main {
 
	section{

		&.hero{
			position: relative;
			overflow: hidden;
			.bg{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
			}
			.content{
				position: relative;
				min-height: calc(100vh - 60px);
				background: transparent url('../../dist/img/gradient.svg') center bottom no-repeat padding-box cover;
				&::before{
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					opacity: .5;
					background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
				}
				.container{
					position: relative;
					@include flex(column,space-between,initial);
					min-height: calc(100vh - 60px);
					h2{
						font-size: 130px;
						text-align: center;
						line-height: 1;
					}
					h3{
						font-size: 80px;
						text-align: center;
						max-width: 240px;
						margin: 0 auto;
					}
					.music-play{
						display: none;
						img{
							width: 180px;
						}
						position: absolute;
						top: calc(50% - 90px);
						right: -70px;
					}
					.social{
						display: none;
					}
					.newsletter{
						padding: 10px 0 20px;
						form{
							@include flex(row,space-between,center);
							label{
								display: none;
							}
							.form-group{
								width: calc(100% - 130px);
								input{
									border: solid 1px $white;
									padding: 10px;
									background-color: transparent;
									color: $black;
									text-transform: uppercase;
									font-size: 20px;
									width: 100%;
									&::placeholder{
										color: $white;
									}
								}
							}
						}
						.form-message{
							text-align: center;
							font-size: 14px;
							text-transform: uppercase;
							letter-spacing: 1px;
							&.form-error{
								margin-top: 5px;
							}
							display: none;
						}
					}
				}
			}
			@include tablet-up{
				.content{
					.container{
						max-width: 100%;
						h2{
							font-size: 250px;
						}
						.bottom{
							h3{
								position: relative;
								max-width: max-content;
								font-size: 180px;
								.music-play{
									top: -90px;
								}
							}
							.wrapper{
								@include flex(row,space-between,center);
								.social{
									@include flex (row,center,center);
									gap:20px;
									a{
										font-size: 20px;
										@include hover-focus{
											color: $pink;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&.divider{
			border-top: solid 1px $white;
			border-bottom: solid 1px $white;
			padding: 20px 0;
			overflow: hidden;
			@include flex(row,flex-start,center);
			gap: 30px;
			p{
				min-width: max-content;
				img{
					width: 54px;
				}
				white-space: nowrap;
				@include flex(row,space-between,center);
				display: flex !important;
				gap: 10px;
				font-size: 30px;
				font-family: $serif;
				text-transform: uppercase;
			}
			// .slick-slide {
			// 	margin: 0 10px;
			// }
			// .slick-list {
			// 	margin: 0 -10px;
			// }
		}

		&.music{
			padding: 40px 0;
			overflow: hidden;
			@include tablet-up{
				padding: 100px 0;
			}
			.container{
				max-width: 100%;
				> a{
					color: $pink;
					font-size: 20px;
					text-align: center;
					text-transform: uppercase;
					font-family: $serif;
					text-align: center;
					display: block;
					text-decoration: underline;
					margin-top: 30px;
				}
				.slider{
					@include tablet-up{
						transform: translateX(200px);
					}
					img{
						width: 800px;
						max-width: 100%;
					}
				}
			}
		}

		&.videos{
			padding: 40px 0;
			overflow: hidden;
			@include tablet-up{
				padding: 100px 0;
			}
			.container{
				max-width: 100%;
				> a{
					color: $pink;
					font-size: 20px;
					text-align: center;
					text-transform: uppercase;
					font-family: $serif;
					text-align: center;
					display: block;
					text-decoration: underline;
					margin-top: 30px;
				}
				.videos-slider{
					a{
						position: relative;
						img, video{
							width: 100%;
							height: 550px;
							object-fit: cover;
							@include tablet-up{
								height: 500px;
							}
						}
						.bottom{
							@include flex(column,center,center);
							gap: 10px;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							padding: 20px;
							h3{
								text-transform: uppercase;
								font-size: 42px;
								text-align: center;
							}
							.btn-pink{
								display: block;
								background-color: $pink;
								color: $black;
								font-family: 'bebas-neue-pro';
								border-radius: 50px;
								pad: 16px 20px 10px;
								font-size: 20px;
								max-width: max-content;
							}
						}
					}
					@include tablet-up{
						transform: translateX(200px);
						a{
							.bottom{
								@include flex(row,space-between,flex-end);
								h3{
									font-size: 72px;
									text-align: left;
								}
							}
						}
					}
				}
			}
		}

		&.tour{
			padding: 40px 0;
			@include tablet-up{
				padding: 200px 0;
			}
			position: relative;
			overflow: hidden;
			.bg{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
			}
			.content{
				position: relative;
				background: transparent url('../../dist/img/gradient.svg') center bottom no-repeat padding-box cover;
				min-height: 100vh;
				.container{
					> a{
						color: $pink;
						font-size: 20px;
						text-align: center;
						text-transform: uppercase;
						font-family: $serif;
						text-align: center;
						display: block;
						text-decoration: underline;
						margin-top: 30px;
					}
					#events{
						display: grid;
						gap: 60px;
						justify-items: center;
						.event{
							text-align: center;
							text-transform: uppercase;
							.event-date{
								color: $pink;
								font-size: 38px;
								font-weight: 700;
								margin-bottom: 5px;
							}
							.event-location,.event-venue{
								font-size: 20px;
								margin-bottom: 5px;
							}
							.btn{
								border: solid 1px $white;
								color: $white;
								@include hover-focus{
									background-color: $white;
									color: $black;
								}
							}
							@include tablet-up{
								@include flex(row,space-between,center);
								width: 100%;
								.event-location{
									text-align: left;
								}
								.event-venue{
									text-align: left;
								}
							}
						}
					}
				}
			}
		}

	}

}

footer {
	padding: 60px 0;
	text-align: center;
	.container{
		@include flex(column-reverse,center,center);
		max-width: 100%;
		gap: 20px;
		.social{
			@include flex(row,center,center);
			gap: 20px;
			a{
				font-size: 20px;
				@include hover-focus{
					color: $pink;
				}
			}
		}
		.copyright{
			p{
				font-size: 8px;
				line-height: 1.4;
			}
		}
		@include tablet-up{
			@include flex(row,space-between,center);
			.copyright{
				text-align: left;
			}
		}
	}

}